<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="
          $t('global.clearingitems') + '(' + verrechnungspositionen.length + ')'
        "
        :col="3"
      >
        <!-- :headline="
            $t('global.clearingitems') + ' (' + verrechnungspositionsCount + ')'
          " -->

        <!-- ToDo: Berechtigungen -->
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="showPositionModal()"
              v-if="berechtigungen.m_finanzen.create"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-danger"
              :disabled="selectedIds.length < 1"
              @click="loeschen"
              v-if="berechtigungen.m_finanzen.delete"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" class="mr-1" />
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </container-headline>

      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="verrechnungspositionen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              show-empty
              selectable
              select-mode="range"
              @row-dblclicked="editieren"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
              <!--<template v-slot:cell(pflicht)="row">
                  <i
                    class="fa"
                    :class="{
                      'fa-check': row.item.pflicht,
                      'fa-times': !row.item.pflicht,
                    }"
                  />
                </template>-->
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <verrechnungsposition-modal
      ref="verrechnungspositionModal"
      id="verrechnungsposition-modal"
      :lehrgang="lehrgang ? lehrgang.id : ''"
      :isTemplate="isTemplate"
      :position="verrechnungsposition"
      :anmeldung="anmeldung ? anmeldung.id : null"
      @position-changed="positionChanged"
    ></verrechnungsposition-modal>
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import VerrechnungspositionModal from "@/components/Bildung/Klasse/VerrechnungspositionModal";

import reiter from "@/mixins/Reiter";

export default {
  name: "BildungsgangVerrechnungspositionen",
  components: {
    ContainerHeadline,
    VerrechnungspositionModal,
  },
  mixins: [reiter],
  props: {
    lehrgang: {
      type: Object,
      required: false,
    },
    anmeldung: {
      type: Object,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      sortDesc: false,
      verrechnungspositionen: [],
      verrechnungsposition: {
        id: null,
        lehrgang: this.lehrgang?.id,
        anzahl: 1,
        manueller_preis: 0,
        artikel: { artikeltyp: {}, einheit: {} },
      },
      verrechnungspositionsCount: 0,
      verrechnungsPositionenLoaded: false,
    };
  },
  watch: {
    shown(val) {
      if (val)
        this.getVerrechnungspositionen(
          this.lehrgang ? this.lehrgang.id : this.anmeldung?.id
        );
    },
    lehrgang: {
      //  immediate: true, // Aufruf der Handler-Funktion sofort beim Erstellen der Komponente
      deep: true, // Überwacht auch Änderungen in verschachtelten Eigenschaften
      handler(newLehrgang) {
        // Überprüfen, ob die lehrgang-Prop und ihre Eigenschaften vorhanden sind
        if (
          newLehrgang &&
          newLehrgang.id &&
          this.shown &&
          !this.verrechnungsPositionenLoaded
        ) {
          this.getVerrechnungspositionen(newLehrgang.id);
          this.verrechnungsPositionenLoaded = true;
        }
      },
    },
    anmeldung: {
      //  immediate: true, // Aufruf der Handler-Funktion sofort beim Erstellen der Komponente
      deep: true, // Überwacht auch Änderungen in verschachtelten Eigenschaften
      handler(newAnmeldung) {
        // Überprüfen, ob die anmeldung-Prop und ihre Eigenschaften vorhanden sind
        if (
          newAnmeldung &&
          newAnmeldung.id &&
          this.shown &&
          !this.verrechnungsPositionenLoaded
        ) {
          this.getVerrechnungspositionen(newAnmeldung.id);
          this.verrechnungsPositionenLoaded = true;
        }
      },
    },
  },
  mounted() {},
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "anzahl",
          sortable: true,
          label: this.$t("global.Number"),
        },
        {
          key: "artikel.artikelnummer",
          sortable: true,
          label: this.$t("global.itemnumber"),
        },
        {
          key: "artikel.bezeichnung",
          sortable: true,
          label: this.$t("global.item"),
        },
        {
          key: "manueller_preis",
          sortable: true,
          label: this.$t("global.price"),
        },
      ];
    },
  },
  created() {},
  methods: {
    getVerrechnungspositionen(id) {
      if (id) {
        var queryParams = {};
        if (this.lehrgang) {
          this.isTemplate
            ? (queryParams.templateLehrgang = id)
            : (queryParams.lehrgang = id);
        } else if (this.anmeldung) {
          queryParams.anmeldung = id;
        }

        Api.get("/rechnungslegung/verrechnungsposition/", {
          params: queryParams,
        }).then((response) => {
          this.verrechnungspositionen = response.data;
          this.verrechnungspositionsCount = this.verrechnungspositionen.length;
        });
      }
    },
    showPositionModal(verrechnungsposition) {
      if (verrechnungsposition)
        this.verrechnungsposition = verrechnungsposition;
      this.$bus.$emit("open-modal", "verrechnungsposition-modal");
    },
    loeschen() {
      Api.delete("/rechnungslegung/verrechnungsposition/", {
        data: this.selectedIds,
      }).then(() => {
        this.positionChanged();
      });
    },
    positionChanged() {
      this.getVerrechnungspositionen(
        this.lehrgang ? this.lehrgang.id : this.anmeldung?.id
      );
    },
    editieren() {},
  },
};
</script>

<style scoped></style>
